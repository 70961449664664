<template>
  <div>
    <confirm-modal ref="confirmModal" />

    <div class="text-center">
      <h4>{{ title }}</h4>

      <template v-if="carrierType !== 'WHOLESALER'">
      <h5>Zwiększ cene produktów o: {{ warehouseCarrierProductPriceIncrease ? warehouseCarrierProductPriceIncrease.value : "0" }}

        <button class="btn btn-sm btn-success" @click="openPriceModal">
          <i class="mdi mdi-pencil"></i>
        </button>
      </h5>

        <h5 class="badge font-size-18" :class="carrierMappings[carrierType] === 'DEFAULT' ? 'badge-soft-primary' : 'badge-soft-warning'">Cennik korzysta z ustawień: {{ carrierMappings[carrierType] === 'DEFAULT' ? "domyślnych" : "indywidualnych" }}</h5>
      </template>
    </div>

    <div class="text-center" v-if="carrierType !== 'WHOLESALER' && !(carrierMappings[carrierType] === 'DEFAULT' || carrierMappings[carrierType] === 'NONE')">
      <b-button @click="$refs.confirmModal.openModal('Przywróć domyślne ustawienia cennika', '', 'Potwierdź', () => restoreDefaultCarriers())" variant="danger" class="btn-sm">Przywróć domyślne ustawienia cennika</b-button>
    </div>

    <div class="rectangle-table">

      <template v-if="carriers">
        <div class="rectangle-content">
          <div class="rectangle" v-for="(warehouseCarrier, index) in carriers" :key="index">

            <div class="ecat-left">
              <div class="button-items">
                <b-button :variant="warehouseCarrier.active ? 'danger' : 'success'" class="btn-sm" @click="changeActiveState(warehouseCarrier)">
                  <i class="fa" :class="warehouseCarrier.active ? 'fa-times' : 'fa-check'"></i>
                </b-button>

                <b-button variant="warning" class="btn-sm" @click="openCarrierModal(warehouseCarrier)">
                  <i class="fa fa-edit"></i>
                </b-button>
              </div>
            </div>

            <div class="centered-data">
              <template v-if="warehouseCarrier.carrierName.length > 20">
                <h6 class="text-center">{{ warehouseCarrier.carrierName }}</h6>
              </template>
              <template v-else>
                <h4 class="text-center">{{ warehouseCarrier.carrierName }}</h4>
              </template>
            </div>

            <div class="ecat-right">
              <b class="text-warning">Cena: {{ priceHelper.format(warehouseCarrier.shippingCost, market.currency) }}</b>
            </div>
          </div>
        </div>
      </template>

      <infinite-loading @infinite="loadMore">
        <span slot="no-more">Nie ma więcej elementów.</span>
        <span slot="no-results">Brak wyników. :(</span>
      </infinite-loading>
    </div>

    <div class="text-center py-2">
      <b-button variant="success" @click="openCarrierModal(null)">Dodaj nową opcję</b-button>
    </div>

    <b-modal
        v-if="form.warehouseCarrier"
        id="modal-1"
        v-model="modals.warehouseCarrierModal"
        :title="form.warehouseCarrier.id ? 'Aktualizacja przewoźnika' : 'Tworzenie nowego przewoźnika'"
        title-class="font-18"
        hide-footer
        @hide="hideCarrierModal"
        @esc="hideCarrierModal">
      <form>

        <div class="form-group" v-if="carrierType !== 'ECAT_ALLEGRO_SMART'">
          <ecat-multiselect
              label="Przewoźnik"
              placeholder="Wybierz przewoźnika"
              :set-value="form.warehouseCarrier.carrierId"
              @change="value => form.warehouseCarrier.carrierId = value"
              @change-object="object => form.warehouseCarrier.carrierName = object.name"
              fetch-one-url="/carrier"
              load-url="/carrier/list/pagination"
              query-url="/carrier/by-name"
              save-id="id"
              view-id="name"
              param="name"
              :class="{ 'is-invalid': submitted && $v.form.warehouseCarrier.carrierId.$error }"
          />
          <div v-if="submitted && !$v.form.warehouseCarrier.carrierId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group" v-if="carrierType !== 'ECAT_ALLEGRO_SMART'">
          <ecat-multiselect
              label="Przewoźnik Allegro"
              placeholder="Wybierz przewoźnika Allegro"
              :set-value="form.warehouseCarrier.allegroCarrierId"
              @change="value => form.warehouseCarrier.allegroCarrierId = value"
              @change-object="object => changeAllegroCarrier(object)"
              fetch-one-url="/allegro/carrier"
              load-url="/allegro/carrier/list/pagination"
              query-url="/allegro/carrier/list/by-name"
              save-id="id"
              view-id="name"
              param="name"
              :class="{ 'is-invalid': submitted && $v.form.warehouseCarrier.allegroCarrierId.$error }"
          />
          <div v-if="allegroCarrier" class="text-danger">Cena minimalna przewoźnika Allegro wynosi: {{ allegroCarrier.firstItemPriceMin }} {{ allegroCarrier.firstItemPriceCurrency }}</div>
          <div v-if="allegroCarrier" class="text-danger">Cena maksymalna przewoźnika Allegro wynosi: {{ allegroCarrier.firstItemPriceMax }} {{ allegroCarrier.firstItemPriceCurrency }}</div>
          <div v-if="submitted && !$v.form.warehouseCarrier.allegroCarrierId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>
        <div class="form-group" v-else>
          <ecat-multiselect
              label="Przewoźnik Allegro"
              placeholder="Wybierz przewoźnika Allegro"
              :set-value="form.warehouseCarrier.allegroCarrierId"
              @change="value => form.warehouseCarrier.allegroCarrierId = value"
              @change-object="object => changeAllegroCarrier(object)"
              fetch-one-url="/allegro/carrier"
              load-url="/allegro/carrier/list/pagination"
              query-url="/allegro/carrier/list/by-name"
              save-id="id"
              view-id="name"
              param="name"
              :class="{ 'is-invalid': submitted && $v.form.warehouseCarrier.allegroCarrierId.$error }"
          />
          <div v-if="allegroCarrier" class="text-danger">Cena minimalna przewoźnika Allegro wynosi: {{ allegroCarrier.firstItemPriceMin }} {{ allegroCarrier.firstItemPriceCurrency }}</div>
          <div v-if="allegroCarrier" class="text-danger">Cena maksymalna przewoźnika Allegro wynosi: {{ allegroCarrier.firstItemPriceMax }} {{ allegroCarrier.firstItemPriceCurrency }}</div>
          <div v-if="submitted && !$v.form.warehouseCarrier.allegroCarrierId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label>Koszt dostawy</label>
          <input v-model.number="form.warehouseCarrier.shippingCost" type="number" class="form-control" :class="{ 'is-invalid': submitted && $v.form.warehouseCarrier.shippingCost.$error }" />
          <div v-if="submitted && !$v.form.warehouseCarrier.shippingCost.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group" v-if="carrierType === 'OVERSIZE'">
          <h4 class="text-center">Typ gabarytu</h4>

          <div class="row d-flex justify-content-center">
            <div class="col-12 col-lg-10">
              <button v-for="(item, index) in packageTypes" :key="index" class="package-type" :class="{ 'active-package': form.warehouseCarrier.oversizeType === item, 'font-size-24': item === 'NONE' }" type="button" @click="form.warehouseCarrier.oversizeType = item">{{ oversizeTypeHelper.getOversizeTypeText(item) }}</button>
            </div>
          </div>

          <input hidden :class="{ 'is-invalid': $v.form.warehouseCarrier.oversizeType.$error }" />
          <div v-if="!$v.form.warehouseCarrier.oversizeType.required" class="invalid-feedback">{{ $t('message.required') }}</div>
        </div>

        <div class="text-center">
          <b-button @click="saveChanges" variant="success">Zapisz</b-button>
          <b-button class="ml-1" variant="danger" @click="hideCarrierModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

    <b-modal
        id="modal-2"
        v-model="modals.priceModal"
        title="Zwiększanie ceny produktów"
        title-class="font-18"
        hide-footer
        @hide="hidePriceModal"
        @esc="hidePriceModal">
      <form>
        <div class="form-group">
          <label>Wartość</label>
          <input v-model.number="formWarehouseCarrierProductPriceIncrease.value" type="number" class="form-control" />
        </div>

        <div class="text-center">
          <b-button @click="changePrice" variant="success">Zapisz</b-button>
          <b-button class="ml-1" variant="danger" @click="hidePriceModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

}

<script>
import {errorCatcher} from "@/helpers/error-catcher";
import axios from "axios";
import Swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";
import confirmModal from "../confirm-modal.vue";
import {oversizeTypeHelper} from "../../helpers/oversize-type-helper";
import {priceHelper} from "../../helpers/price-helper";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "warehouse-carrier-table",
  components: {
    confirmModal
  },

  props: {
    title: {
      type: String,
      required: true
    },

    carrierType: {
      type: String,
      required: true
    },

    warehouse: {
      type: Object,
      required: true
    },

    market: {
      type: Object,
      required: true
    },

    carrierMappings: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      submitted: false,

      carriers: [],
      page: 1,

      modals: {

        warehouseCarrierModal: false,
        priceModal: false

      },

      form: {
        warehouseCarrier: null
      },

      allegroCarrier: null,

      formWarehouseCarrierProductPriceIncrease: {
        value: 0
      },

      warehouseCarrierProductPriceIncrease: {
        value: 0
      },

      shouldUpdate: false,
      packageTypes: [ "TYPE_A", "TYPE_B", "TYPE_C", "NONE"]
    }
  },

  validations() {
    if (this.carrierType === "ECAT" || this.carrierType === "WHOLESALER") {
      return {
        form: {
          warehouseCarrier: {
            carrierId: {required},
            allegroCarrierId: {required},
            shippingCost: {required}
          }
        }
      }
    } else if (this.carrierType === "ECAT_ALLEGRO_SMART") {
      return {
        form: {
          warehouseCarrier: {
            allegroCarrierId: {required},
            shippingCost: {required}
          }
        }
      }
    } else if (this.carrierType === "OVERSIZE") {
      return {
        form: {
          warehouseCarrier: {
            carrierId: {required},
            allegroCarrierId: {required},
            shippingCost: {required},
            oversizeType: { required }
          }
        }
      }
    }
  },

  computed: {
    priceHelper() {
      return priceHelper
    },

    oversizeTypeHelper() {
      return oversizeTypeHelper
    }
  },

  async mounted() {
    try {
      const { data } = await axios.get(`/warehouse/carrier/product-price-increase`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "carrierType": this.carrierType,
          "warehouseId": this.warehouse.id
        }
      });

      this.warehouseCarrierProductPriceIncrease = data
    } catch (error) {
      errorCatcher.catchErrors(error)
    }
  },

  methods: {
    changeAllegroCarrier(object) {
      this.allegroCarrier = object

      if (this.carrierType === "ECAT_ALLEGRO_SMART") {
        this.form.warehouseCarrier.carrierName = object.name
      }
    },

    async restoreDefaultCarriers() {
      try {
        const json = {
          warehouseId: this.warehouse.id,
          carrierType: this.carrierType
        }

        await axios.post(`/warehouse/carrier/restore-default-carriers`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.$root.$emit("admin-reload-warehouse-carriers")
        await Swal.fire("Sukces!", `Przywrócono domyślne ustawienia cennika`, "success")
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    loadMore($state) {
      const perPage = 5;
      let page = this.page - 1;
      if (page > 0) {
        page = this.page * perPage - perPage;
      }

      axios.get(`/warehouse/carrier/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "warehouseId": this.warehouse.id,
          "type": this.carrierType,
          "page": page,
          "size": perPage
        },
      }).then(({ data }) => {
        if (data.warehouseCarriers && data.warehouseCarriers.length) {
          this.page += 1;

          const warehouseCarriers = data.warehouseCarriers.map(carrier => {
            return {
              ...carrier,
              frontendId: carrier.id,
            };
          });

          this.carriers.push(...warehouseCarriers);
          $state.loaded();
        } else {
          $state.complete();
        }
      }).catch((error) => {
        $state.complete();
        errorCatcher.catchErrors(error)
      });
    },

    changeActiveState(warehouseCarrier) {
      warehouseCarrier.active = !warehouseCarrier.active
      this.shouldUpdate = true

      Swal.fire("Sukces!", warehouseCarrier.active ? "Aktywowano przewoźnika" : "Dezaktywowano przewoźnika", "success")
      this.$forceUpdate()
    },

    openCarrierModal(warehouseCarrier) {
      if (warehouseCarrier) {
        this.form.warehouseCarrier = Object.assign({}, warehouseCarrier)
      } else {
        this.form.warehouseCarrier = {
          warehouseId: this.warehouse.id,
          carrierId: "",
          allegroCarrierId: "",
          shippingCost: 0,
          oversizeType: "NONE",
          active: false,
          type: this.carrierType
        }
      }

      this.modals.warehouseCarrierModal = true
    },

    hideCarrierModal() {
      this.allegroCarrier = null
      this.form.warehouseCarrier = null
      this.modals.warehouseCarrierModal = false
    },

    saveChanges() {
      if (this.carrierType === "ECAT_ALLEGRO_SMART" && this.allegroCarrier && this.form.warehouseCarrier.shippingCost < this.allegroCarrier.firstItemPriceMin) {
        Swal.fire("Błąd!", `Cena minimalna przewoźnika Allegro wynosi: ${this.allegroCarrier.firstItemPriceMin} ${this.allegroCarrier.firstItemPriceCurrency}`, "error")
        return
      }

      if (this.carrierType === "ECAT_ALLEGRO_SMART" && this.allegroCarrier && this.form.warehouseCarrier.shippingCost > this.allegroCarrier.firstItemPriceMax) {
        Swal.fire("Błąd!", `Cena maksymalna przewoźnika Allegro wynosi: ${this.allegroCarrier.firstItemPriceMax} ${this.allegroCarrier.firstItemPriceCurrency}`, "error")
        return
      }

      this.submitted = true
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.shouldUpdate = true
      Swal.fire("Sukces!", this.form.warehouseCarrier.id ? "Zaktualizowano przewoźnika" : "Dodano nowego przewoźnika", "success")

      if (this.form.warehouseCarrier.frontendId) {
        this.carriers = this.carriers.map(carrier => {
          if (carrier.frontendId === this.form.warehouseCarrier.frontendId) {
            return this.form.warehouseCarrier;
          } else {
            return carrier;
          }
        });
      } else {
        this.carriers.push({
          frontendId: uuidv4(),
          ...this.form.warehouseCarrier
        })
      }

      this.hideCarrierModal()
    },

    isShouldUpdate() {
      return this.shouldUpdate;
    },

    getCarrierTypeName() {
      if (this.carrierType === "OVERSIZE") {
        return "Gabaryty"
      }

      return this.carrierType.replaceAll("_", " ")
    },

    async commitChanges() {
      if (!this.shouldUpdate) {
        return
      }

      const json = JSON.stringify({
        "warehouseCarriers": this.carriers
      })

      try {
        const { data } = await axios.post(`/warehouse/carrier`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        await Swal.fire("Sukces!", `Zatwierdzono zmiany dla tabelki ${this.getCarrierTypeName()}`, "success")
        this.carriers = data.map(carrier => {
          return {
            ...carrier,
            frontendId: carrier.id,
          };
        });
        this.shouldUpdate = false
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    openPriceModal() {
      this.formWarehouseCarrierProductPriceIncrease = Object.assign({}, this.warehouseCarrierProductPriceIncrease)
      this.modals.priceModal = true
    },

    hidePriceModal() {
      this.modals.priceModal = false
      this.formWarehouseCarrierProductPriceIncrease = {
        value: 0
      }
    },

    changePrice() {
      const json = JSON.stringify({
        ...this.formWarehouseCarrierProductPriceIncrease,
        "warehouseId": this.warehouse.id,
        "carrierType": this.carrierType
      });

      axios.put(`/warehouse/carrier/product-price-increase`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(({ data }) => {
        this.$bvToast.toast(this.$t("Zmieniono wartość"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.hidePriceModal()
        this.warehouseCarrierProductPriceIncrease = data
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    }

  }

}
</script>

<style scoped>
.rectangle-table {
  justify-content: center;
  max-height: 600px;
  overflow-y: auto;
}

.rectangle-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.rectangle {
  width: 250px;
  height: 100px;
  background-color: var(--primary);
  border: 1px solid #0061d0;
  color: var(--white);
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.centered-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.ecat-left {
  text-align: left;
  margin-right: auto;
  margin-left: 5px;
  margin-top: 5px;
}

.ecat-right {
  text-align: right;
  margin-left: auto;
}
</style>