export const jsonUtil = {
    asArray
};

function asArray(array) {
    try {
        return JSON.parse(array);
    } catch (error) {
        return []
    }
}